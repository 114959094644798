import React, { useContext, useEffect, useState } from 'react';
import { Container, Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, DialogContentText, InputAdornment } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSpacingParams } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getAllGuides ,DeleteGuideByGuideId, GetGudeDetailsByGuideId } from '../../services/GuideService';
import { formatDateTime } from '../common/TimeZoneConversion';
import ClearIcon from "@mui/icons-material/Clear";
import settingsiconAnnouncements from "../../assets/icons/SettingsiconAnnouncements.svg";
// import SettingsPage from './SettingsPage';

import { useSnackbar } from '../../SnackbarContext';
import CreateNewPopup from '../common/CreateNewGuidePopup';
import { useAuth } from '../auth/AuthProvider';
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { AccountContext } from '../account/AccountContext';
import CloneInteractionDialog from '../common/CloneGuidePopup';
import { useTranslation } from 'react-i18next';
// import Settings from '../settings/Settings';
import { Settings, Fileedit, clone, Delete } from "../../assets/icons/icons";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

interface Banner {
AccountId: string;
  Content: string;
  CreatedBy: string;
  CreatedDate: string;
  Frequency: string;
  GuideId: string;
  GuideStatus: string;
  GuideType: string;
  Name: string;
  OrganizationId: string;
  Segment: string;
  TargetUrl: string;
  TemplateId: string;
  UpdatedBy: string;
  UpdatedDate: string;
}

const Banners = () => {
  const { t: translate } = useTranslation();
  const [activeTab, setActiveTab] = useState(0); 
  const [banners, setBanners] = useState<Banner[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());

  const [tourslist, setTourslist] = useState([]);
  const[name,setName]=useState("Banner")
  const { signOut, userDetails } = useAuth();
  const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId ?? "");
  const [totalcount, setTotalcount] = useState(0);
  const [orderByFields, setOrderByFields] = useState("");
  const [filters, setFilters] = useState([]);
  const [skip, setskip] = useState(0);
  const [top, settop] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { openSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [guideIdToDelete, setGuideIdToDelete] = useState<string | null>(null);
  const [GuidenametoDelete, setGuideNametoDelete] = useState('');
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
	const [cloneBannerName, setCloneBannerName] = useState<Banner | null>(null);
	const [bannerName, setBannerName] = useState<Banner | null>(null);
	const [searchQuery, setSearchQuery] = useState('');
  const { accountId } = useContext(AccountContext);
  



  const [showPopup, setShowPopup] = useState(false);
  const userInfo = localStorage.getItem('userInfo');
  let userData = null;  
  if (userInfo) {
      try {
          const parsedInfo = JSON.parse(userInfo);
          userData = parsedInfo ? parsedInfo.user : null;
      } catch (error) {
          console.error("Error parsing user info:", error);
          userData = null;
      }
  }  
  const organizationId = userData?.OrganizationId;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (searchQuery.trim() === '') {
      fetchBanners(); 
    }
  }, [searchQuery]); 

  const fetchBanners = async () => {
    const { page, pageSize } = paginationModel;
    const offset = page * pageSize;
    const statusFilter = activeTab === 0 ? 'Active' : activeTab === 1 ? 'InActive' : 'Draft';
    setBanners([]);
    const filters = [
      {
        FieldName: 'GuideType',
        ElementType: 'string',
        Condition: 'equals',
        Value: 'Banner',
        IsCustomField: false,
      },
      {
        FieldName: 'GuideStatus',
        ElementType: 'string',
        Condition: 'equals',
        Value: statusFilter,
        IsCustomField: false,
		},
		{
			FieldName: 'Name', 
			ElementType: 'string',
			Condition: 'contains',
			Value: searchQuery,
			IsCustomField: false,
      },
      {
        FieldName: 'AccountId', 
        ElementType: 'string',
        Condition: 'contains',
        Value: accountId,
        IsCustomField: false,
        },
    ];
    const orderByFields = '';
    const data = await getAllGuides(offset, pageSize, filters, orderByFields);
    if (data._count > 0)
    {
      setBanners(data.results);
      setTotalCount(data._count); 
    }  
    else {
      setBanners([]);
      setTotalCount(0); 
    }
	};
	const changeTabToDraft = () => {
		setActiveTab(3);
	  };
  const handleSearch = () => {
	fetchBanners(); 
  };
 
  const handleKeyDown = (event: React.KeyboardEvent) => {
	if (event.key === 'Enter') {
	  handleSearch(); 
	}
  };
  const handleEditClick = (guide: Banner) => {
    const targetUrl = guide?.TargetUrl; 
    if (targetUrl) {
      window.open(targetUrl, '_blank');
    } 
   
  };
  useEffect(() => {
    if (accountId) {
      fetchBanners();
    }
  }, [paginationModel, activeTab,accountId]);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };

  const handleDelete = async () => {
    if (guideIdToDelete) {
      try {
        const response = await DeleteGuideByGuideId(guideIdToDelete);
        if (response.Success) {
          openSnackbar(`${GuidenametoDelete} ${name} deleted Successfully`, "success");
          await fetchBanners();  
        } else {
          openSnackbar(response.ErrorMessage, "error");
        }
      } catch (error) {
       
      }
    }
    setOpenDialog(false);
    setGuideIdToDelete(null);
    setGuideNametoDelete("");
  };
  const handleDeleteConfirmation = (guideId: string) => {
    setGuideIdToDelete(guideId);
    setOpenDialog(true);
  };
  const handleCopyClick = (banner: Banner) => {
    setCloneBannerName(banner);
    setIsCloneDialogOpen(true);
	};
	const navigate = useNavigate(); // Hook to get navigate function

	// const handleSettingsClick = (guideName: string) => {
    //     navigate('/settings-page', { state: { guideName } }); // Pass the guide name in the state
	// };
	// const handleSettingsClick = (guideData: any) => {
	// 	//<SettingsPage {...guideData={guideData}}/>
	// 	navigate('/settings-page', { state: { guideData } }); // Pass the complete guide object
  //   };

    const handleSettingsClick = async (params: any) => {
      const guideId = params.GuideId;
      const response = await GetGudeDetailsByGuideId(guideId);
      if (response) {  
        navigate('/settings-page', { state: { response } });
      } else {
        openSnackbar("GuideId Was empty", "error");
      }
    };
	
  const handleCloneSuccess = async () => {
    await fetchBanners();
  };
  const openPopup = () => {
		setShowPopup(true);
	};
  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Name',
      // width: 434,
      hideable: false,
resizable:false
    },
    {
      field: 'UpdatedDate',
      headerName: 'Last Edited',
      // width: 250,
      hideable: false,
      renderCell: (params) => (
        <span>
        {`${formatDateTime(params.row.UpdatedDate, 'dd-MM-yyyy')}`}
        </span>
      ),
resizable:false
    },
       
    {
      field: 'actions',
      headerName: 'Actions',
      // width: 400,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Tooltip arrow title="Edit">
          <IconButton onClick={() =>handleEditClick(params.row)}>
          <img src={Fileedit} alt="Edit"/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Clone">
            <IconButton onClick={() => handleCopyClick(params.row)}>
            <img src={clone} alt="Clone"/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Settings">
            <IconButton onClick={() => handleSettingsClick(params.row)}>
            <SettingsOutlinedIcon sx={{color:"#000"}} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete">
            <IconButton onClick={() => {
              handleDeleteConfirmation(params.row.GuideId);
              setGuideNametoDelete(params.row.Name);
            }}>
                     <img src={Delete} alt="Delete" style={{ filter: "brightness(0.5)" }} />
 
            </IconButton>
          </Tooltip>
        </>
      ),
      resizable:false
    },
  ];

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);
  const handleClearSearch = () => {
    setSearchQuery('');
    fetchBanners();
  };
  return (
    <Container maxWidth="xl">
        <div className='qadpt-web'>
        <div className='qadpt-webcontent'>
        <div className="qadpt-head">
							<div className="qadpt-title-sec">
              <div className="qadpt-title">{translate('Banners')}</div>
							</div>
							<div className="qadpt-right-part">
								<button
									onClick={openPopup}
									className="qadpt-memberButton"
								>
									<i className="fal fa-add-plus"></i>
                  <span>{translate('Create Banner')}</span>
								</button>
							</div>
          </div>

          <div className="qadpt-tabs-container">
	<Tabs value={activeTab} onChange={handleTabChange}>
  <Tab label={translate('Active')} />
        <Tab label={translate('InActive')} />
		<Tab label={translate('Draft')} />
	</Tabs>
          </div>
          <div className="qadpt-websearch-container">
    <TextField
        variant="outlined"
        placeholder={translate('Search Banner')}
              value={searchQuery}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
                }}
        onChange={(e) => {
            const newValue = e.target.value;
            setSearchQuery(newValue);
            if (newValue === "") {
                handleClearSearch();
            }
        }}
        className="qadpt-websearch"
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <IconButton
                        aria-label="search"
                        onClick={() => handleSearch()}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            ),
            endAdornment: searchQuery && (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear"
                        onClick={() => {
                            setSearchQuery("");
                            handleClearSearch();
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
</div>


      <div  className='qadpt-webgrd qadpt-anngrd'>
        <DataGrid
      
          rows={banners}
          columns={columns}
          getRowId={(row) => row.GuideId}
          getRowSpacing={getRowSpacing}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={totalCount}
          pageSizeOptions={[15, 25, 50, 100]}
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: 'Records Per Page',
            },
            noRowsLabel:`No Banners`
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
      {openDialog && (
     <div className="qadpt-modal-overlay">       
  <div className="qadpt-usrconfirm-popup qadpt-danger">
    <div className="qadpt-popup-title">
    <div className="qadpt-icon">      
        <IconButton
							className="qadpt-svg">
								<i className='fal fa-trash-alt'></i>
								</IconButton>
      </div>
      <div className="qadpt-popup-title-text">
        {translate('Delete Announcement')}
      </div>
    </div>

    <div className="qadpt-warning">
      {translate('The')} <span className="qadpt-delete-popup-bold">{GuidenametoDelete}</span> {translate('cannot be restored once it is deleted.')}
    </div>

    <div className="qadpt-buttons">
      <button
        onClick={() => setOpenDialog(false)}
        className="qadpt-cancel-button"
      >
        {translate('Cancel')}
      </button>
      <button
        onClick={handleDelete}
        className="qadpt-conform-button"
      >
        {translate('Delete')}
      </button>
    </div>
              </div>
              </div>
)}
      {isCloneDialogOpen && cloneBannerName && (
        <CloneInteractionDialog
              open={isCloneDialogOpen}
              handleClose={() => setIsCloneDialogOpen(false)}
              initialName={cloneBannerName}
              onCloneSuccess={handleCloneSuccess}
              name={name}
        />
      )}
          {showPopup ? (
            <>
            <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
              zIndex: 999, // Below popup but above rest of content
            }}
          />

          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the popup
              zIndex: 1000, // Higher than the overlay
              backgroundColor: "white", // Popup background
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
           <CreateNewPopup
							 setTourslist={setBanners}
               setShowPopup={setShowPopup}
               showPopup={showPopup}
               setTotalcount={setTotalCount}
               setActiveTab={setActiveTab}
               activeTab={activeTab}
               changeTab={changeTabToDraft}
               name={name}
						   />
          </div>
           </>
							 
						) : (
							""
          )}
        </div>
        </div>
    </Container>
  );
};

export default Banners;
