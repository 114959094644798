import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/AuthProvider";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import userManager from "../auth/UseAuth";
import { getOrganizationById } from "../../services/OrganizationService";
import { User } from "../../models/User";
import { Organization } from "../../models/Organization";
import jwt_decode from "jwt-decode";
import { LoginUserInfo } from "../../models/LoginUserInfo";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

let OrganizationId: string;
let UserId: string;
let userDetails: User;
let OrgDetails: Organization;

const Home: React.FC = () => {
	const { signOut} = useAuth();
	const [loginUserDetails, setUserDetails] = useState<User | null>(null);
	const [organizationDetails, setOrganizationDetails] = useState<Organization | null>(null);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [userId, setUserId] = useState(String);
	const [organizationId] = useState(String);
	const [loginUserInfo, setLoginUserInfo] = useState<LoginUserInfo | undefined>(undefined);
	const location = useLocation();
	const Navigate = useNavigate();

	useEffect(() => {
		let token = localStorage.getItem("access_token");
		const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
		if (userInfo['oidc-info'] && userInfo['user'] && userInfo['orgDetails']) {
			userDetails = JSON.parse(userInfo['user'])
			OrgDetails = JSON.parse(userInfo['orgDetails'])
			OrganizationId = OrgDetails.OrganizationId;
			token = JSON.parse(userInfo['oidc-info']).access_token;
		}		
		const lastpath = localStorage.getItem("last_path") ;
		if (token) {
			try {				
				if (userDetails?.UserType === "SuperAdmin") {
					Navigate("/superadmin/organizations");
				}				
				const loggedinUserInfo = jwt_decode<LoginUserInfo>(token);
				
				if (lastpath && lastpath != "")
				{
					localStorage.setItem("last_path","");
					Navigate(lastpath);
				}
			} catch (error) {

				signOut();
			}
		}
		else {
			signOut();
		}
	}, [userDetails]);
const{t:translate}=useTranslation();
	return (
		<div
			className={`smooth-transition`}
			style={{ marginRight:133}}
		>
			<h1 style={{ textAlign: "center" }}>{translate('Home Page')}</h1>
			{userDetails && (
				<div>
					<p style={{ textAlign: "center" }}>Welcome, {userDetails.FirstName}</p>
				</div>
			)}
		</div>
	);
};

export default Home;
export { OrganizationId, UserId };
